body {
    font-family: 'Roboto', sans-serif;
     padding: 0!important;
     padding: 0!important;
 }
 
 html body{ margin: 0!important; padding: 0!important;}
 
 h1,
 h2,
 h3,
 h4,
 h5,
 p {
     padding: 0;
     margin: 0;
  
     font-weight: normal;
 }
 
 a {
     text-decoration: none;
 }
 
 button {
     border: 0;
     cursor: pointer;
 }
 
 

/* ==================LoginForm====================================== */

.login_main_wrpper {
    justify-content: center;
    background: rgb(242, 252, 66);
    background: linear-gradient(180deg, rgba(242, 252, 66, 1) 0%, rgba(251, 252, 66, 1) 7%, rgba(66, 113, 252, 1) 100%);
}

input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 35px #ffffff inset !important;
    background-color: #000 !important;
}

input:-internal-autofill-selected {
    background-color: -internal-light-dark(rgb(255, 255, 255), rgb(255, 255, 255)) !important;
    -webkit-box-shadow: 0 0 0 35px #ffffff inset !important;
}

.login_main_wrpper .forgot_password {
    text-decoration: none;
    color: #1877f2;
    font-weight: 500;
}





/* ===========================Responsive Elements ============================ */
@media only screen and (max-width: 900px){
.login_main_wrpper .MuiPaper-root {
    width: 80% !important;
    margin: 0 auto !important;
}
.login_main_wrpper .passBttn {
    background-color: #1877f2;
}


.login_main_wrpper .passBttn span {
    font-size: 20px;
    font-weight: 600;
}

}

 
 

img {
    border: 0;
    outline: 0;
    max-width: 100%
}

a img {
    outline: none
}

a {
    outline: none;
    cursor: pointer
}

a:active,
a:focus {
    outline: none
}

:active,
:focus {
    outline: none;
    -moz-outline-style: none
}

:link:focus,
:visited:focus {
    outline: none
}

 
 
 
 
  

/* =========================== for admin css ============================ */

.noDataAvailable{ font-size: 24px; padding: 20px 10px; color: #ff0000;}
.admin_page{background: #e7eef1; min-height: 75vh;     overflow: hidden;}

.admin_h1_class{ background-color: #0070af; padding: 15px; font-size: 24px;      box-shadow: 0 3px 8px #c6cfd3; color: #fff;}
 
.admin_page_wrapper{   margin: 15px;}

.admin_page_wrapper .MuiPaper-root{ box-shadow: none!important; background: none!important;}

.css-pjjt8i-MuiToolbar-root{ min-height: inherit!important; margin:0 !important; padding: 0!important;}


#tableTitle{background-color: #0070af; padding: 15px; font-size: 24px; min-height: inherit; margin: 0;     box-shadow: 0 3px 8px #c6cfd3;
     color: #fff;}


/* =========================== end  admin css ============================ */

