 
 
  .error-page{
   
    color: #042241;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
  }
  
  .error-page h1{
    margin: 0;
    color: #042241;
    font-size: 120px;
    font-weight: bold;
    text-align: center;
  
  }
  
 
  
  
  .admin_topNav{   background: rgb(125,155,185);
    background: linear-gradient(180deg, rgba(125,155,185,1) 0%, rgba(84,105,126,1) 100%);
      padding: 15px;
      border-radius: 0px;
   
      text-align: center;}
  
  .admin_topNav  button{ color: #fff; font-size: 16px; padding: 5px 12px; text-transform: capitalize;}   
  .admin_topNav  a{ color: #fff; font-size: 16px;}   
  
  .footerblock{font-size: 14px;
 
      color: #737476;
      text-align: center; padding: 20px 5px;
   
       background-color: #191818;}
  
    
    .admin_topbody{background: rgb(226,226,226);
      background: linear-gradient(180deg, rgba(226,226,226,1) 0%, rgba(255,255,255,1) 100%);  padding: 15px; 
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;     align-items: center;
   }   
  
  
    .admin_topbody .adminlogo{ width: auto; max-width: 280px;}
  
    .topuserblock{ display: flex;
      flex-wrap: wrap;
      justify-content: center;     align-items: center; }
  
  
    .topuserblock h1{ margin: 0; padding: 0 15px; font-size: 18px;}
  
    .topuserblock .PersonIcon {
      background: #25ace3;
      color: #fff;
      width: 30px;
      height: 30px;
      border-radius: 100px;
      padding: 5px;
  }
  .topuserblock Button{ margin: 0; padding: 0; min-width: inherit;}
  
  .topuserblock .userarrowicon{ background-color: #1ea5de;
      color: #fff;
      width: 20px;
      height: 20px;
      border-radius: 100px;
      padding: 0px; margin: 0;}
  
  
      .admin_topNav button.active{ background:#22b2e7; }
 
 
  
  
  @media only screen and (max-width: 640.98px) {
  
      .admin_topbody{ display: block;}
  
      .admin_topbody .adminlogo{ display: block; margin: 0 auto; margin-bottom: 15px; max-width: 90%;}
  
  
      
   }