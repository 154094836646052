.commonWidth{
    width: 1820px;
    margin: 0 auto;
}
@media only screen and (max-width: 1820px){
    .commonWidth{
        width: 96% !important;
    }
}
.HomeMain_container .HomeBanner_container{
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/Home_MainBanner.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.HomeMain_container .homeBanner_Main{
    padding: 52px 0;
}
.HomeMain_container .bannerTop{
    background: rgb(234,249,253);
background: linear-gradient(180deg, rgba(234,249,253,1) 0%, rgba(253,255,255,1) 50%, rgba(234,249,253,1) 100%);
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px;
}
.topRight_text{
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/TopHeader_bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 78%;
    border-radius: 5px;
    padding: 20px;
}
.topRight_text h1 span{
 
    background: rgb(250,251,252);
    background: linear-gradient(180deg, rgba(250,251,252,1) 0%, rgba(168,188,199,1) 100%);
    -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}
.topRight_text h1{
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    color: #ffe800;
    text-transform: uppercase;
}

.topLogo{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.bannerMiddle{
    display: flex;
    justify-content: space-between;
    margin: 14px 0;
    align-items: center;
}
.videoWrapper{
    width: 49%;
    display: block;
    /* height: 100%; */
    overflow: hidden;
    padding-top: 0;
    position: relative;
    /* padding-top: 7px; */
}
.videoWrapper iframe{
    border: none;
    bottom: 0;
    height:414px;
    left: 0;
    position: inherit;
    right: 0;
    top: 0;
    width: 100%;
}
.rightcontent_Wrapper{
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: -5px;
}
.bannerBottom{
    border: 9px solid rgba(255, 255, 255, 0.411);
}
.bannerBottomInner{
    background: rgb(0,182,250);
    background: linear-gradient(180deg, rgba(0,182,250,1) 0%, rgba(1,97,150,1) 100%);
}
.bannerBottomInner p{
    font-size: 40px;
    font-weight: 600;
    background: rgb(249,249,249);
background: linear-gradient(180deg, rgba(249,249,249,1) 0%, rgba(209,211,212,1) 100%);
-webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      padding: 16px;
}
.fooTerCopyright{
    background: #191818;
    margin-top: -5px;
}
.fooTerCopyright p{
color: #737476;
font-size: 16px;
padding: 25px;
text-align: center;
}
.bottomAllSection_main{
    margin-top: -5px;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/aspire_home_+Onlybg+copy.webp);
    background-size: cover;
    background-repeat: no-repeat;
}
.home-middle-bck{
    margin-top: -5px;
}
/* ====temporary css================== */
.formWrapperMain{
    background-color: #ffffff;
    width: 470px;
    /* margin: 0 auto; */
    padding: 10px;
    border-radius: 7px;
    box-shadow: 0px 1px 4px 8px #0000001f;
}
.lastSection_logo img{
    width: 99%;
    display: block;
    margin: 0 auto;
}
.unilityBlock_main img{
    margin: -73px 0 0 0;
}
/* .formWrapMain{
    background: beige;
} */
/* ====temporary css================== */

.formWrapperMain .formInline{
    background: #fff;
    /* padding: 10px; */
    /* width: 480px; */
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
}
.formWrapperMain .formInline .countDiv__column--col12{
    flex: 1 0 100%;
    margin: 0 0 12px 0;
}
.formWrapperMain .formInline .countDiv__column--col12 .MuiFormControl-root{
    width: 100%;
    background-color: #e8e7e7;
}
.formWrapperMain .formInline .countDiv__column--col12 .MuiFormLabel-root{
    font-size: 18px;
    font-weight: 500;
    color: #000;
}
/* .formWrapperMain .formInline .countDiv__column--col12 .MuiFormLabel-root .MuiInputBase-root .MuiInputBase-root{
    background: #e8e7e7;
} */
.formWrapperMain .formHeader{
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/formBanner.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px;
    padding: 17px 12px ;
    margin-bottom: 12px;
}
.formWrapperMain .formHeader h2{
    font-size: 29px;
    font-weight: 600;
    background: rgb(249,249,249);
background: linear-gradient(180deg, rgba(249,249,249,1) 0%, rgba(214,215,217,1) 100%);
-webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      line-height: 37px;
}
.formWrapperMain .multipleCheckbox{
    width: 100%;
    margin-bottom: 23px;
    margin-top: 8px;
}
.submitbtnsection{
    width: 100%;
}
.formWrapperMain .multipleCheckbox .MuiFormControl-root{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}
.formWrapperMain .multipleCheckbox  .MuiFormControlLabel-root{
    width: 45%;
    font-size: 18px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin-bottom: -18px;
}
.formWrapperMain .multipleCheckbox .MuiFormLabel-root{
    font-size: 18px;
    font-weight: 500;
    color: #042241;
}
.formWrapperMain .submitbtnsection{
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/FormButtonBnr.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    cursor: pointer;
}
.formWrapperMain .submitbtnsection button {
    display: flex;
    margin: 0 auto;
    width: 100%;
    /* cursor: pointer;  */
}
.formWrapperMain .submitbtnsection button span{
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
    color: #fff
}
.formWrapperMain .MuiFormHelperText-root.Mui-error{
    color: #ff0000;
    position: absolute;
    right: 0;
    top: 28%;
}
.formSction_subWrp{
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
}
.leftSection_texts{
    width: 53%;
}
.formWrapMain{
    width: 46%;
    background-image: url(https://all-frontend-assets.s3.amazonaws.com/Bizopp-Goldrush-FrontEnd_Assets/BIZOpp_human.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right top;
}